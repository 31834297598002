import { createSlice } from "@reduxjs/toolkit";
import { FlashbarProps } from "@cloudscape-design/components";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "..";

interface IBreadCrumb {
    text: string
    href: string
}

interface IState {
    pageTitle: string;
    sideNavOpen: boolean;
    helpPanelOpen: boolean;
    breadCrumbs: IBreadCrumb[];
    infoMessages: FlashbarProps.MessageDefinition[];
}

const initialState: IState = {
    pageTitle: "Dependency Sentinel",
    sideNavOpen: false,
    helpPanelOpen: false,
    breadCrumbs: [
        { text: "Home", href: "/#" },
    ],
    infoMessages: [{
        type: "info",
        dismissible: true,
        content: "Development in progress",
        dismissLabel: 'development',
        id: "message_1",
    }],
};

export const uiSlice = createSlice({
    name: "ui.AppLayout",
    initialState,
    reducers: {
        setPageTitle: (state, action: PayloadAction<string>) => {
            state.pageTitle = action.payload;
        },
        setSideNavOpen: (state, action: PayloadAction<boolean>) => {
            state.sideNavOpen = action.payload;
        },
        setHelpPanelOpen: (state, action: PayloadAction<boolean>) => {
            state.helpPanelOpen = action.payload;
        },
        appendBreadCrumb: (state, action: PayloadAction<IBreadCrumb>) => {
            state.breadCrumbs.push(action.payload);
        },
        popBreadCrumb: (state) => {
            state.breadCrumbs.pop();
        },
        appendInfoMessage: (state, action: PayloadAction<FlashbarProps.MessageDefinition>) => {
            state.infoMessages.push(action.payload);
        },
        removeInfoMessage: (state, action: PayloadAction<FlashbarProps.MessageDefinition>) => {
            state.infoMessages = state.infoMessages.filter(msg => msg.id !== action.payload.id);
        }
    }
});

export const {
    setPageTitle,
    setSideNavOpen,
    setHelpPanelOpen,
    appendBreadCrumb,
    popBreadCrumb,
    appendInfoMessage,
    removeInfoMessage
} = uiSlice.actions;

const selectAppLayout = (state: RootState) => state['ui.AppLayout']
export const selectPageTitle = (state: RootState) => selectAppLayout(state).pageTitle;
export const selectSideNavOpen = (state: RootState) => selectAppLayout(state).sideNavOpen;
export const selectHelpPanelOpen = (state: RootState) => selectAppLayout(state).helpPanelOpen;
export const selectBreadCrumbs = (state: RootState) => selectAppLayout(state).breadCrumbs;
export const selectInfoMessages = (state: RootState) => selectAppLayout(state).infoMessages;

export default uiSlice.reducer;
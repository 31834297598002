import React, { useEffect, useState } from "react";
import {
    AppLayout,
    BreadcrumbGroup,
    ContentLayout,
    Flashbar,
    Header,
    HelpPanel,
    Link,
    SideNavigation,
    SideNavigationProps,
} from "@cloudscape-design/components";
import { I18nProvider } from "@cloudscape-design/components/i18n";
import cloudscapeMessages from "@cloudscape-design/components/i18n/messages/all.en";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    setSideNavOpen,
    setHelpPanelOpen,
    selectPageTitle,
    selectSideNavOpen,
    selectHelpPanelOpen,
    selectBreadCrumbs,
    selectInfoMessages,
    removeInfoMessage
} from "src/web/redux";
import { useAppDispatch } from "src/web/redux/hooks";


const LOCALE = "en";

const menuItems = [
    {
        type: "link",
        text: "Overview",
        href: "/#/",
    } as SideNavigationProps.Item,
    {
        type: "link",
        text: "Onboard",
        href: "/onboard",
    } as SideNavigationProps.Item,
    {
        type: "link",
        text: "My Teams",
        href: "/team",
    } as SideNavigationProps.Item,
];


const sideNavMenuItems = menuItems;

sideNavMenuItems.push({
    type: "link",
    text: "Contact Us",
    href: "https://w.amazon.com/bin/view/DependencySentinel",
    external: true,
} as SideNavigationProps.Item);

export const SentinelLayout = () => {
    const pageTitle = useSelector(selectPageTitle);
    const navOpen = useSelector(selectSideNavOpen);
    const helpPanelOpen = useSelector(selectHelpPanelOpen);
    const breadCrumbs = useSelector(selectBreadCrumbs);
    const infoMessages = useSelector(selectInfoMessages);
    const dispatch = useAppDispatch();
    const [messages, setMessages] = useState<typeof infoMessages>([])

    useEffect(() => {
        const mappedMessages = infoMessages.map(message => ({
            ...message,
            onDismiss: () => dispatch(removeInfoMessage(message))
        }));
        setMessages(mappedMessages)
    }, [infoMessages])

    return (
        <I18nProvider locale={LOCALE} messages={[cloudscapeMessages]}>
            <AppLayout
                breadcrumbs={
                    <BreadcrumbGroup
                        items={breadCrumbs}
                    />
                }
                navigationOpen={navOpen}
                navigation={
                    <SideNavigation
                        header={{
                            href: "#",
                            text: "Dependency Sentinel",
                        }}
                        items={sideNavMenuItems}
                    />
                }
                notifications={
                    <Flashbar
                        items={messages}
                    />
                }
                onNavigationChange={(e) => dispatch(setSideNavOpen(e.detail.open))}
                onToolsChange={(e) => dispatch(setHelpPanelOpen(e.detail.open))}
                toolsOpen={helpPanelOpen}
                tools={<HelpPanel header={<h2>To get Started</h2>}>Help content</HelpPanel>}
                content={
                    <ContentLayout
                        header={
                            <Header variant="h1" info={<Link variant="info">Info</Link>}>
                                {pageTitle}
                            </Header>
                        }
                    >
                        <Outlet />
                    </ContentLayout>
                }
            />
        </I18nProvider>
    );
};

export default SentinelLayout;

import { combineReducers, configureStore } from "@reduxjs/toolkit";

import uiSlice from "./slices/ui";

const rootReducer = combineReducers({
    "ui.AppLayout": uiSlice,
})

export const setupStore = (preloadedState?: Partial<RootState>) => 
    configureStore({
        reducer: rootReducer,
        preloadedState,
    });

export const store = configureStore({
    reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof setupStore>;

export const useUIStore = (state: RootState) => state["ui.AppLayout"];
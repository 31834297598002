import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Form,
    FormField,
    Input,
    Select,
    SpaceBetween,
} from "@cloudscape-design/components";
import {
    setPageTitle,
    appendBreadCrumb,
} from "src/web/redux";
import { SentinelPageProps } from "../../interfaces";
import { SentinelBasePage } from "../sentinel-base";

export interface SentinelOnboardingPageProps extends SentinelPageProps {
    readOnly?: boolean;
}

export const OnboardingPage: React.FC<SentinelOnboardingPageProps> = (
    props: SentinelOnboardingPageProps
) => {
    const [entered, setEntered] = useState(false);
    const [managerLogin, setManagerLogin] = useState("");
    const [submitButtonLabel, setSubmitButtonLabel] = useState("Fetch Packages");
    const [selectedTeamName, selectTeamName] = useState({
        label: "Select Team",
        value: "None",
    } as { label?: string; value?: string });
    const [selectedInputType, selectInputType] = useState({
        label: "Select Input Type",
        value: "None",
    } as { label?: string; value?: string });
    // Set page title
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle("Onboarding Page"));
        dispatch(appendBreadCrumb({ text: "Onboarding", href: "/onboard" },));
    }, []);
    const handleManagerInput = (event: any) => {
        setManagerLogin(event.detail.value);
    };
    const setTeamName = (selectedTeamName: any) => {
        selectTeamName(selectedTeamName);
    };

    return (
        <SentinelBasePage header="Get Started">
            <Form
                actions={
                    <SpaceBetween direction="vertical" size="s">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                variant="primary"
                            >
                                {submitButtonLabel}
                            </Button>
                        </SpaceBetween>
                    </SpaceBetween>
                }
            >
                <FormField>
                    <div className="formfield-margin">
                        <strong>Requester Alias: janeru </strong>
                    </div>
                </FormField>

                <FormField>
                    <div className="formfield-margin">
                        <strong>Mananger Login </strong>
                        <Input
                            type="text"
                            placeholder="Please enter team owner login"
                            value={managerLogin}
                            onChange={handleManagerInput}
                            onBlur={() => setEntered(true)}
                        />
                    </div>
                </FormField>
                <FormField>
                    <div className="formfield-margin">
                        <strong>Team:</strong>
                    </div>
                    {props.readOnly ? (
                        <Input readOnly={true} value="Choose Team" />
                    ) : (
                        <Select
                            disabled={managerLogin == ""}
                            selectedOption={selectedTeamName}
                            onChange={({ detail }) =>
                                setTeamName(detail.selectedOption)
                            }
                            options={[
                            ]}
                        />
                    )}
                </FormField>
            </Form>
        </SentinelBasePage>
    );
};
